import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from './app.types';

const initialState: AppState = {
  activeBranchId: '28b18793-ee3d-4219-a804-c31faa08c0cf',
  language: 'de',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveBranchId: (
      state,
      { payload }: PayloadAction<AppState['activeBranchId']>
    ) => {
      state.activeBranchId = payload;
    },
    setActiveLanguage: (
      state,
      { payload }: PayloadAction<AppState['language']>
    ) => {
      state.language = payload;
    },
  },
});

const { actions, reducer } = appSlice;

export const { setActiveBranchId, setActiveLanguage } = actions;

export { initialState };

export default reducer;
