import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BasicIngredientModel,
  ExtraIngredientModel,
} from '../../../../models/ingredients.model';
import {
  OrderIngredientModel,
  OrderLineModel,
} from '../../../../models/order.model';
import { ProductSizeModel } from '../../../../models/sizes.model';

const initialState: OrderLineModel = null as any;

const productOptionsSlice = createSlice({
  name: 'ProductOptions',
  initialState,
  reducers: {
    resetProduct: () => {
      return initialState;
    },

    setProduct: (state, { payload }: PayloadAction<OrderLineModel>) => {
      return { ...state, ...payload };
    },

    setProductSize: (state, { payload }: PayloadAction<ProductSizeModel>) => {
      state.activeSize = payload;
      state.selectedExtras = [];
    },

    setProductComment: (state, { payload }: PayloadAction<string>) => {
      state.comment = payload;
    },

    addProductExtraIngredient: (
      state,
      { payload }: PayloadAction<OrderIngredientModel<ExtraIngredientModel>>
    ) => {
      state.selectedExtras.push({ ...payload, quantity: 1 });
    },

    changeProductExtraIngredientAmount: (
      state,
      { payload }: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const extraIngredientIndex = state.selectedExtras.findIndex(
        (orderLineIngredient) =>
          orderLineIngredient.ingredient.id === payload.id
      );

      state.selectedExtras[extraIngredientIndex].quantity = payload.quantity;
    },

    // todo: add only excluded basic ingredients to the excludedBasics array
    setProductBasicIngredients: (
      state,
      { payload }: PayloadAction<OrderIngredientModel<BasicIngredientModel>[]>
    ) => {
      state.excludedBasics = payload;
    },

    changeProductBasicIngredientAmount: (
      state,
      { payload }: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const basicIngredientIndex = state.excludedBasics.findIndex(
        (orderLineIngredient) =>
          orderLineIngredient.ingredient.id === payload.id
      );

      state.excludedBasics[basicIngredientIndex].quantity = payload.quantity;
    },

    removeProductExtraIngredient: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.selectedExtras = state.selectedExtras.filter(
        (orderLineIngredient) => orderLineIngredient.ingredient.id !== payload
      );
    },

    increaseProductAmount: (state) => {
      state.quantity += 1;
    },

    decreaseProductAmount: (state) => {
      state.quantity -= 1;
    },
  },
});

const { actions, reducer } = productOptionsSlice;

export const {
  resetProduct,
  setProduct,
  setProductSize,
  setProductComment,
  setProductBasicIngredients,
  increaseProductAmount,
  decreaseProductAmount,
  addProductExtraIngredient,
  removeProductExtraIngredient,
  changeProductExtraIngredientAmount,
  changeProductBasicIngredientAmount,
} = actions;

export { initialState };

export default reducer;
