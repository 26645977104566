import React from 'react';

import { SideMenu } from '../SideMenu';
import style from './Layout.module.scss';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={style.Layout}>
      <SideMenu />
      <main id="main" className={style.content}>
        {children}
      </main>
    </div>
  );
};

export { Layout };
