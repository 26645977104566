import React from 'react';

import { Account } from './Account';
import logoPath from './logo.svg';
import { Navigation } from './Navigation';
import style from './SideMenu.module.scss';

const SideMenu = () => {
  return (
    <div className={style.SideMenu}>
      <div className={style.logo}>
        <img src={logoPath} alt="logo" />
      </div>

      <Navigation />

      <Account />
    </div>
  );
};

export { SideMenu };
