import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import style from './SideMenuItem.module.scss';

export type SideMenuItemProps = {
  icon: React.ReactNode;
  title?: string;
  link?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const SideMenuItem = ({
  icon,
  title,
  link,
  onClick,
  isActive,
}: SideMenuItemProps) => {
  if (link) {
    return (
      <Link to={link}>
        <li
          className={classNames(style.SideMenuItem, {
            [style.active]: isActive,
          })}
          title={title}
        >
          {icon}
        </li>
      </Link>
    );
  }

  return (
    <li className={style.SideMenuItem} title={title} onClick={onClick}>
      {icon}
    </li>
  );
};

export { SideMenuItem };
