import { Route, Routes } from 'react-router-dom';

import { routes } from '../../routes';
import { Layout } from '../Layout';

const App = () => {
  return (
    <Layout>
      <Routes>
        {routes.map((route, index) => (
          <Route {...route} key={index} />
        ))}
      </Routes>
    </Layout>
  );
};

export default App;
