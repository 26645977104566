import { request } from '../axios';
import { CategoriesRequest, CategoryDto } from './categories.types';

const getCategories = async ({
  branchId,
  page,
  size,
  sort,
}: CategoriesRequest) => {
  const { data } = await request.get<CategoryDto[]>(
    `/product/public/api/v1/moneta/branches/${branchId}/categories`
  );
  return data;
};

export { getCategories };
