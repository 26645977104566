import { orderManagementPath } from 'apps/frontend-app-moneta/src/routes/orderManagement';
import { orderOverviewPath } from 'apps/frontend-app-moneta/src/routes/orderOverview';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { SideMenuItem } from '../SideMenuItem';
import { ReactComponent as BuzzerIcon } from './icons/buzzer.svg';
import { ReactComponent as CarIcon } from './icons/car.svg';
import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as PlanetIcon } from './icons/planet.svg';
import style from './Navigation.module.scss';

const items = [
  {
    link: '/',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    link: orderManagementPath,
    title: 'Order management',
    icon: <CarIcon />,
  },
  {
    title: 'title2',
    icon: <BuzzerIcon />,
  },
  {
    title: 'title3',
    icon: <PlanetIcon />,
  },
  {
    link: orderOverviewPath,
    title: 'Order overview',
    icon: <ListIcon />,
  },
];

const Navigation = () => {
  const { pathname } = useLocation();
  const getIsActiveMenuItem = (link: string | undefined): boolean => {
    if (link === '/' && pathname === '/') {
      return true;
    }

    if (link && link !== '/' && pathname !== '/') {
      return pathname.startsWith(link);
    }

    return false;
  };

  return (
    <nav>
      <ul className={style.Navigation}>
        {items.map(({ title, icon, link }) => (
          <SideMenuItem
            key={title}
            icon={icon}
            title={title}
            link={link}
            isActive={getIsActiveMenuItem(link)}
          />
        ))}
      </ul>
    </nav>
  );
};

export { Navigation };
