import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderModel } from '../../../../models/order.model';

const initialState: OrderModel = null as any;

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    resetOrderDetails: () => {
      return initialState;
    },

    setOrderDetails: (state, { payload }: PayloadAction<OrderModel>) => {
      return { ...state, ...payload };
    },
  },
});

export const { actions, reducer } = orderDetailsSlice;

export const { resetOrderDetails, setOrderDetails } = actions;

export default reducer;
