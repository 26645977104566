import { configureStore } from '@reduxjs/toolkit';

import appReducer from './slices/app/app.slice';
import { orderManagement } from './slices/orderManagement';
import { orderOverview } from './slices/orderOverview';

const store = configureStore({
  reducer: {
    app: appReducer,
    orderManagement,
    orderOverview
  },
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

export { AppDispatch, RootState };

export default store;
