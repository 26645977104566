import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { CustomerModel } from '../../../../models/customer.model';
import {
  CreateOrderModel,
  OrderLineModel,
  OrderModel,
} from '../../../../models/order.model';

const orderLineComparator: (keyof OrderLineModel | 'product.id')[] = [
  'product.id',
  'comment',
  'activeSize',
  'selectedExtras',
  'excludedBasics',
];

const initialState: CreateOrderModel = {
  orderId: '',
  orderType: 'delivery',
  orderLines: [],
  cost: { deliveryPrice: 0, taxValue: 0, totalCost: 0, totalDiscount: 0 },
};

const orderSlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    setOrder: (state, { payload }: PayloadAction<OrderModel>) => {
      return payload;
    },

    resetOrder: () => {
      return initialState;
    },

    addOrderLine: (state, { payload }: PayloadAction<OrderLineModel>) => {
      const orderLine = state.orderLines.find((orderLine) =>
        isEqual(
          pick(orderLine, orderLineComparator),
          pick(payload, orderLineComparator)
        )
      );

      if (orderLine) {
        orderLine.quantity += payload.quantity;
        if (orderLine.quantity > 99) {
          orderLine.quantity = 99;
        }
      } else {
        state.orderLines = [
          ...state.orderLines,
          { ...payload, lineId: uuidv4() },
        ];
      }
    },

    updateOrderLine: (
      state,
      {
        payload,
      }: PayloadAction<
        OrderLineModel & Required<Pick<OrderLineModel, 'lineId'>>
      >
    ) => {
      const lineIndex = state.orderLines.findIndex(
        (orderLine) => orderLine.lineId === payload.lineId
      );
      if (lineIndex === -1) {
        throw new Error('Order does not contain OrderLine');
      }

      state.orderLines[lineIndex] = payload;
    },

    removeOrderLine: (
      state,
      { payload }: PayloadAction<Required<OrderLineModel['lineId']>>
    ) => {
      const line = state.orderLines.find(
        (orderLine) => orderLine.lineId === payload
      );
      if (!line) {
        throw new Error('Order does not contain OrderLine');
      }

      line.quantity--;

      if (!line.quantity) {
        state.orderLines = state.orderLines.filter(
          (orderLine) => orderLine.lineId !== line.lineId
        );
      }
    },

    setCustomer: (state, { payload }: PayloadAction<CustomerModel>) => {
      state.orderCustomer = payload;
    },

    resetCustomer: (state) => {
      state.orderCustomer = undefined;
    },
  },
});

const { actions, reducer } = orderSlice;

export const {
  removeOrderLine,
  setOrder,
  updateOrderLine,
  addOrderLine,
  setCustomer,
  resetCustomer,
  resetOrder,
} = actions;

export { initialState };

export default reducer;
