import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderOverviewModel } from '../../../../models/orderOverview.model';

// todo: OrderListState ?
type OrderListSlice = { activeOrder?: OrderOverviewModel };

const initialState: OrderListSlice = {};

export const orderListSlice = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    resetActiveOrder: () => {
      return initialState;
    },

    setActiveOrder: (state, { payload }: PayloadAction<OrderOverviewModel>) => {
      state.activeOrder = payload;
    },
  },
});

export const { actions, reducer } = orderListSlice;

export const { resetActiveOrder, setActiveOrder } = actions;

export default reducer;
