import loadable from '@loadable/component';
import { Navigate, RouteProps } from 'react-router-dom';

import NotFound from '../pages/NotFound';
import { orderCreateFullPath } from './orderManagement';

const OrderManagement = loadable(
  () => import('../pages/OrderManagement/OrderManagement')
);

const OrderOverview = loadable(
  () => import('../pages/OrderOverview/OrderOverview')
);

const Main = loadable(() => import('../pages/Main'));

export const routes: RouteProps[] = [
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/order-management/:event/order/*',
    element: <OrderManagement />,
  },
  {
    path: '/order-management/*',
    element: <OrderManagement />,
  },
  {
    path: '/order-management',
    element: <Navigate to={orderCreateFullPath} />,
  },
  {
    path: '/order-overview',
    element: <OrderOverview />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
