import { combineReducers } from '@reduxjs/toolkit';

import menuReducer from './menu/menu.slice';
import orderReducer from './order/order.slice';
import orderLineReducer from './productOptions/productOptions.slice';

const orderManagement = combineReducers({
  menu: menuReducer,
  order: orderReducer,
  orderLine: orderLineReducer,
});

export { orderManagement };
