import { ApiDto } from '../api.types';
import { request } from '../axios';
import {
  ProductByArticleNumberRequest,
  ProductDto,
  ProductRequest,
  ProductsRequest,
} from './products.types';

const getProducts = async ({ branchId, categoryId }: ProductsRequest) => {
  const { data } = await request.get<ApiDto<ProductDto>>(
    `product/public/api/v1/moneta/branches/${branchId}/categories/${categoryId}/products`
  );
  return data;
};

const getProduct = async ({ productId }: ProductRequest) => {
  const { data } = await request.get<ProductDto>(
    `product/public/api/v1/moneta/products/${productId}`
  );
  return data;
};

const getProductByArticleNumber = async ({
  branchId,
  articleNumber,
}: ProductByArticleNumberRequest) => {
  const { data } = await request.get<ProductDto>(
    `product/public/api/v1/moneta/branches/${branchId}/products/article/${articleNumber}`
  );
  return data;
};

export { getProduct, getProductByArticleNumber, getProducts };
