import './styles/main.scss';

import React from 'react';
import * as ReactDOM from 'react-dom';

import { getCategories } from './api/categories/categories.queries';
import { CategoryDto } from './api/categories/categories.types';
import { getProducts } from './api/products/products.queries';
import { queryClient } from './api/queryClient';
import App from './components/App';
import Providers from './components/Providers';
import store from './redux/store';

const Main = () => (
  <Providers>
    <App />
  </Providers>
);

/* Possible implementation of preloading */
const init = async (): Promise<void> => {
  const {
    app: { activeBranchId },
  } = store.getState();

  await queryClient.prefetchQuery<CategoryDto[]>('categories', () =>
    getCategories({ branchId: activeBranchId })
  );

  const categories = queryClient.getQueryData<CategoryDto[]>('categories');

  if (!categories) {
    throw new Error('Prefetching categories is not success');
  }

  await queryClient.prefetchQuery('products', () =>
    getProducts({
      branchId: activeBranchId,
      categoryId: categories[0].id,
    })
  );

  ReactDOM.render(<Main />, document.getElementById('root'));
};

ReactDOM.render(<Main />, document.getElementById('root'));

// init().then();
