import React from 'react';

import { SideMenuItem } from '../SideMenuItem';
import style from './Account.module.scss';
import { ReactComponent as LogOutIcon } from './icons/log-out.svg';
import { ReactComponent as AccountIcon } from './icons/user-settings.svg';

const items = [
  {
    title: 'Log out',
    icon: <LogOutIcon />,
  },
  {
    title: 'Manage account',
    icon: <AccountIcon />,
  },
];

const Account = () => {
  return (
    <ul className={style.Account}>
      {items.map(({ title, icon }) => (
        <SideMenuItem key={title} icon={icon} title={title} />
      ))}
    </ul>
  );
};

export { Account };
