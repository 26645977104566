import { generatePath } from 'react-router';

type OrderManagementEvent = 'create' | 'edit';
type OrderEditRoute = { orderId: string };
type OrderMaganementRoute = { event: OrderManagementEvent };
type ProductOptionsAddRoute = { productId: string };
type ProductOptionsEditRoute = { productId: string; lineId: string };

const orderManagementPath = '/order-management';

const orderCreatePath = 'create/order';
const orderEditPath = 'edit/order';
const orderCheckoutPath = 'checkout';

const productOptionsAddPath = 'add/product/:productId';
const productOptionsEditPath = 'update/product/:productId/:lineId';

const customerAddPath = 'add/customer';
const customerUpdatePath = 'update/customer/:customerId';

const orderCreateFullPath = `${orderManagementPath}/${orderCreatePath}`;
const orderEditFullPath = `${orderManagementPath}/${orderEditPath}`;
const orderCheckoutFullPath = `${orderManagementPath}/${orderCheckoutPath}`;

const productOptionsEditFullPath = `${orderManagementPath}/${productOptionsEditPath}`;
const productOptionsAddFullPath = `${orderManagementPath}/${productOptionsAddPath}`;

const customerAddFullPath = `${orderManagementPath}/${customerAddPath}`;
const customerUpdateFullPath = `${orderManagementPath}/${customerUpdatePath}`;

const getProductOptionsAddRoute = ({
  productId,
}: ProductOptionsAddRoute): string =>
  generatePath(productOptionsAddPath, { productId });

const getProductOptionsEditRoute = ({
  productId,
  lineId,
}: ProductOptionsEditRoute): string =>
  generatePath(productOptionsEditPath, { productId, lineId });

const getOrderEditRoute = ({
    orderId,
  }: OrderEditRoute): string =>
    generatePath(orderEditFullPath, { orderId });

export {
  customerAddFullPath,
  customerAddPath,
  customerUpdateFullPath,
  customerUpdatePath,
  getOrderEditRoute,
  getProductOptionsAddRoute,
  getProductOptionsEditRoute,
  orderCheckoutFullPath,
  orderCheckoutPath,
  orderCreateFullPath,
  orderCreatePath,
  orderEditFullPath,
  orderEditPath,
  OrderEditRoute,
  OrderMaganementRoute,
  OrderManagementEvent,
  orderManagementPath,
  productOptionsAddFullPath,
  productOptionsAddPath,
  ProductOptionsAddRoute,
  productOptionsEditFullPath,
  productOptionsEditPath,
  ProductOptionsEditRoute};
