import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MenuState } from './menu.types';

const initialState: MenuState = {
  activeCategoryId: '',
  totalPages: 0,
  page: 0,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveCategoryId: (
      state,
      { payload }: PayloadAction<MenuState['activeCategoryId']>
    ) => {
      state.activeCategoryId = payload;
    },

    setTotalPages: (
      state,
      { payload }: PayloadAction<MenuState['totalPages']>
    ) => {
      state.totalPages = payload;
    },

    setPage: (state, { payload }: PayloadAction<MenuState['page']>) => {
      state.page = payload;
    },
  },
});

const { actions, reducer } = menuSlice;

export const { setActiveCategoryId, setTotalPages, setPage } = actions;

export { initialState };

export default reducer;
